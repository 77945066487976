@import 'src/styles/variables';

.cardPreviewContainer {
  position: relative;
  width: 230px;
  margin: 0 auto 12px;

  .cardPreview svg {
    width: 230px;
    height: 145px;
  }

  .cardText {
    font-size: 9px;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: absolute;
    bottom: 16%;
    left: 7%;
  }
}

.gold {
  color: $gold;
}

.silver {
  color: $white;
}

.purple {
  color: $white;
}
