@import 'src/styles/mixins';
@import 'src/styles/media';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.title {
  @include h1;
  margin-bottom: 8px;

  @include sm {
    margin-bottom: 16px;
  }
}

.thankYouIcon {
  width: 155px;

  svg {
    width: 100%;
    height: auto;
  }
}
