@import 'src/styles/variables';
@import 'src/styles/mixins';

.checkbox {
  margin-bottom: 40px;
}

.dropdownContainer {
  position: relative;
}

.dropdownOptionsList {
  @include dropdownOptionsList;
  top: 92px;
}

.dropdownOption {
  @include dropdownOption;
}
