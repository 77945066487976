@import 'src/styles/variables';
@import 'src/styles/mixins';

.inputContainer {
  position: relative;
}

.input {
  @include input;
}

.label {
  @include inputLabel;
}

.hint {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 300;
}

.error {
  border: 1px solid $red;
}
