@import 'src/styles/mixins';
@import 'src/styles/variables';

.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $black100;
  margin-bottom: 8px;
  text-align: left;

  @include sm {
    margin-bottom: 4px;
  }
}

.totalLoanAmountContainer {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $black6;

  .termTitle {
    padding-bottom: 10px;
  }

  @include sm {
    margin-bottom: 4px;
  }
}

.loanBreakdownDataContainer {
  margin-top: 12px;
}

.termTitle {
  font-size: 16px;
  line-height: 24px;
  color: $black60;
}

.termValue {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $black100;
}

.expanderLabel {
  display: flex;
  align-items: center;
}

.loanBreakdownLine {
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 15px;

  @include sm {
    margin-bottom: 10px;
  }
}

.expanderContainer {
  text-align: left;
  font-size: 12px;
}

.expanderOpen {
  border-bottom: 1px solid $black6;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.tradelineContainer {
  display: flex;
  align-items: center;

  .termValue {
    margin-left: auto;
    padding-right: 35px;
  }
}
