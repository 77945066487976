@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  position: relative;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;
  min-height: 36px;
  display: flex;
  align-items: center;
}

.checkboxLabel {
  font-size: 14px;
  line-height: 24px;
  padding-left: 35px;
  color: $black80;

  @include sm {
    font-size: 16px;
    line-height: 28px;
  }
}

.container input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid $black10;
  box-sizing: border-box;
  background-color: $white;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.15);
  outline: none;

  &.disabled {
    background-color: #ebebe4;
    cursor: default;
  }
}

.checkboxCheckmark {
  border-radius: 4px;
}

.container:hover input ~ .checkmark {
  transition: 0.3s background;
}

.container input:checked ~ .checkmark {
  border: 0;
  background-color: $green;
}

.checkmark::after {
  content: '';
  display: none;
}

.container input:checked ~ .checkmark::after {
  display: block;
  width: 12px;
  height: 12px;
}

.container .checkboxCheckmark::after {
  content: '';
  background-image: url('check-small.svg');
  background-repeat: no-repeat;
  background-size: 12px 12px;
}
