@import 'src/styles/mixins';
@import 'src/styles/variables';

.verifiedContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.verifiedLabel {
  color: $green;
  text-transform: uppercase;
  font-weight: $boldFontWeight;
}

.fieldWithStatus {
  @include verticalGap(20px);
}
