@import 'src/styles/mixins';

.title {
  text-align: center;
  margin-bottom: 24px;
  font-size: 24px;
}

.subtitle {
  font-size: 16px;
  text-align: center;
  color: $black60;
}

.outputValueContainer {
  @include sm {
    margin-bottom: 40px;
  }

  margin-bottom: 24px;
}

.descriptionContainer {
  margin: 0 auto;

  .descriptionItems {
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    background-color: $white;
    text-transform: none;
    color: $black100;
    font-style: normal;
    font-size: 14px;
  }

  .item {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 1fr;
    margin-bottom: 12px;
  }

  .item:last-child {
    margin-bottom: 0;
  }

  .itemText {
    @include body-text(md);
    margin-left: 12px;
  }
}

.button {
  margin: 0 0 20px;
}

.secondaryButton {
  width: 100%;
  margin: 0 auto;
}

.verified {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.verifiedLabel {
  color: $green;
  text-transform: uppercase;
  font-weight: $boldFontWeight;
}
