@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  @include offerContainer($background: true);
  height: 70vh;
  width: 100%;
  color: $black100;
  margin-left: -16px;
  margin-top: 24px;
  margin-bottom: 24px;
  box-sizing: content-box;

  @include sm {
    margin: 24px auto 48px;
    padding: 40px 160px 32px;
  }

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .fadeContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.container__blue,
.container__dark,
.container__purple {
  background-color: rgba(255, 255, 255, 0.12);

  circle {
    stroke: $white !important;
  }
}
