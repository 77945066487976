@import 'src/styles/mixins';

.container {
  @include stepsContainer;
  text-align: center;
}

.title {
  @include h1;
  text-align: center;
  margin: 0 auto;
  max-width: 320px;

  @include sm {
    max-width: initial;
  }
}

.description {
  max-width: 460px;
  margin: 24px auto;
  @include body-text(md);
}

.outputValueContainer {
  margin-bottom: 24px;
}

.outputValueItem:not(:last-child) {
  margin-bottom: 24px;
}

.subtitle {
  @include subtitle;
  margin-bottom: 4px;
  text-align: center;

  @include sm {
    margin-bottom: 8px;
  }
}

.notification {
  @include notification;
  max-width: 328px;
  margin: 16px auto;

  @include sm {
    margin: 0 auto 24px;
  }

  a {
    color: $blue;
    text-decoration: none;

    &:hover {
      color: $darkBlue;
    }
  }
}

.outputValue {
  @include h1;
  text-align: center;
}

.button {
  display: block;
  margin: 24px auto 0;
}
