@import 'src/styles/variables';
@import 'src/styles/mixins';

.layoutContainer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
  position: relative;
  background-color: $black6;
  z-index: 2;
}

.layoutContent {
  flex-grow: 1;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  column-fill: balance;
  z-index: 1;
}

.confetti {
  width: 100vw;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.hexagonTop {
  position: absolute;
  top: 0;
  right: 0;
}

.hexagonMiddle {
  position: absolute;
  right: 0;
  top: 50%;
}

.hexagonBottom {
  position: absolute;
  transform: rotate(180deg);
  bottom: 0;
  left: 0;
}

.cardBackground {
  position: absolute;
  top: 50%;
  right: -10%;
  z-index: 0;
  opacity: 0.25;

  @include sm {
    top: 30%;
    right: 18%;
    transform: scale(2.8);
  }
}
