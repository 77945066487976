@import 'src/styles/mixins';
@import 'src/styles/variables';

.formNavigation {
  @include sm {
    width: 720px;
    padding: 0;
    margin: 20px auto 16px;
  }
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 36px;

  margin-top: 8px;
  margin-bottom: 16px;

  .backLink {
    flex: 1;
    width: auto;
    margin: 0;

    .backLinkTitle {
      @include sm {
        display: block;
      }
      display: none; // hide in mobile
    }
  }

  .holder {
    flex: 1;
  }

  .title {
    @include body-text(sm, 500);
    text-align: center;
    flex: 1;
    color: $black60;
    white-space: nowrap;
  }

  .asHeading {
    @include h1;
  }

  .steps {
    @include body-text(sm, 500);
    text-align: right;
    flex: 1;
    color: $black60;
  }
}
