@import 'src/styles/mixins';
@import 'src/styles/variables';

.loyaltyContainer {
  width: auto;
  background-color: $white;
  box-shadow: $boxShadow;
  padding: 32px 16px;
  border-radius: 24px;
  box-sizing: border-box;
  margin-top: 16px;
  display: flex;
  gap: 24px;
  align-items: center;

  .message {
    width: 100%;
  }

  .gift {
    width: 42px;
  }
}

.buttonContainer {
  @include verticalGap(20px);
}
