@import 'src/styles/mixins';
@import 'src/styles/variables';

.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kycStatusContainer {
  display: flex;
  align-items: center;
}

.checkIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.verifiedLabel {
  color: $green;
  text-transform: uppercase;
  font-weight: $boldFontWeight;
}

.reviewingDocumentsLabel {
  color: $primaryColor;
  text-transform: uppercase;
  font-weight: $boldFontWeight;
}

.descriptionContainer {
  .descriptionItems {
    display: flex;
    flex-direction: column;
    background-color: $white;
    text-transform: none;
    color: $black100;
    font-style: normal;
    font-size: 14px;
  }

  .descriptionItems {
    @include verticalGap(12px);
  }

  .item {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 1fr;
  }

  .itemText {
    @include body-text(md);
    margin-left: 12px;
  }
}
