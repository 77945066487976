@import 'src/styles/mixins';

.checkIcon {
  width: 56px;
  height: 56px;
}

.subtitle {
  @include verticalGap(24px);
}
