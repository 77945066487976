@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  @include stepsContainer;
  text-align: center;
  margin-top: 40px;
}

.iconContainer {
  margin-top: 40px;
  margin-bottom: 24px;
}

.title {
  @include h2;
  margin-bottom: 24px;
}

.description {
  @include subtitle;
  margin-bottom: 24px;
  text-align: center;
}
