@import 'src/styles/variables';

:export {
  $loaderColor: $white;
}

.loader {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -43%);
}
