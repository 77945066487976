@import 'src/styles/variables';
@import 'src/styles/mixins';

.description {
  line-height: 160%;
  color: $black80;
  text-align: center;
}

.subtitleContainer {
  @include verticalGap(24px);
}

.buttonContainer {
  @include verticalGap(24px);
}
