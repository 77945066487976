@import 'src/styles/variables';
@import 'src/styles/mixins';

.title {
  @include h1;
  text-align: center;
  margin-bottom: 8px;

  @include sm {
    margin-bottom: 16px;
  }
}

.subtitle {
  @include subtitle;
  text-align: center;
  margin-bottom: 24px;
}

.card {
  border: 1px solid $black10;
  border-radius: 12px;
  box-shadow: $deepBoxShadow;
  padding: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 32px;
}

.image {
  margin-bottom: 12px;
  max-width: 124px;
}

.testimony {
  color: $black60;
  margin-bottom: 12px;
}

.name {
  @include body-text(lg, $semiBoldFontWeight);
}

.credentials {
  @include body-text(md, $regularFontWeight);
  color: $black60;
}

.button {
  margin-bottom: 16px;
}
