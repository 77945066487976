@import 'src/styles/variables';

.container {
  display: flex;
  gap: 12px;
  align-items: center;
  position: relative;
}

.col {
  border-radius: 12px;
  border: 1px solid $black10;
  background: $white;
  box-shadow: 0 16px 20px 0 rgba(36, 85, 158, 0.08);
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.importLabel {
  position: absolute;
  left: 50%;
  translate: -50% 0;
  border-radius: 50%;
  background-color: $black6;
  border: 1px solid $black10;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: $black100;
  }
}

.colIcon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: $black6;
}

.colLabel {
  margin-top: 8px;
  font-size: 14px;
  font-weight: $mediumFontWeight;
  color: $black80;
}
