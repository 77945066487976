@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 32px;
  border-radius: 12px;
  text-align: center;
  height: 32px;
  background-color: transparentize($grayLight, 0.4);
}

.label {
  @include body-text(md, $boldFontWeight);
}
