@import 'src/styles/variables';
@import 'src/styles/mixins';

.options {
  @include verticalGap(24px);
  width: 100%;
}

.option {
  @include verticalGap(8px);
  align-items: center;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid $black10;
  background: $white;
  box-shadow: $boxShadow;
  cursor: pointer;
  transition: 0.2s;
}

.option.selected {
  border-color: $purple500;
  box-shadow: 0 3px 10 0 rgba(121, 90, 247, 0.21);
}

.heading {
  @include body-text('lg', $boldFontWeight);
  align-items: center;
  width: 100%;
  text-align: center;
}

.heading.hasChip {
  display: flex;
  gap: 8px;
  width: auto;
}

.selected .heading {
  color: $purple500;
}

.chip {
  @include body-text('sm', $boldFontWeight);
  padding: 0 4px;
  border-radius: 2px;
  background: $purple500;
  color: $white;
}

.subHeading {
  @include body-text('lg', $regularFontWeight);
}

.description {
  @include body-text('md', $regularFontWeight);
  text-align: center;
  @include verticalGap(16px);
}
