@import 'src/styles/mixins';
@import 'src/styles/variables';

.termTitle {
  font-size: 16px;
  line-height: 24px;
  color: $black60;
}

.termValue {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $black100;
}

.tradelineContainer {
  display: flex;
  align-items: center;

  .termValue {
    margin-left: auto;
    padding-right: 35px;
  }
}

.tradelineLogo {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
