@import 'src/styles/variables';
@import 'src/styles/mixins';

.step {
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid $black10;
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(48, 52, 101, 0.07);
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.name {
  @include body-text('lg', $boldFontWeight);
}

.arrowIcon {
  margin-left: auto;
  width: 20px;
  height: 20px;
}
