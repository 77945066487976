@import 'src/styles/mixins';
@import 'src/styles/variables';

.inputContainer {
  position: relative;
}

.input {
  @include input;
}

.label {
  @include inputLabel;
}

.error {
  border: 1px solid $red;
}

.readOnly {
  background-color: $black6;
  color: $black40;
  cursor: default;
}

.inputContainer__dark {
  color: $white;
}
