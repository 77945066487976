@import 'src/styles/variables';
@import 'src/styles/mixins';

.container:not(:last-child) {
  border-bottom: solid 1px $black10;
  padding-bottom: 24px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
}

.header:hover .arrow {
  animation: move 500ms linear;
}

.icon {
  width: 48px;
  height: 48px;
  margin-right: 12px;
}

.title {
  font-size: 14px;
  font-weight: $semiBoldFontWeight;
}

.arrow {
  margin-left: auto;
  rotate: -90deg;

  path {
    stroke: $primaryColor;
  }
}

.content {
  font-size: 14px;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }

  33% {
    transform: translateY(4px);
  }

  66% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(0);
  }
}
