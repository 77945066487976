@import 'src/styles/variables';
@import 'src/styles/mixins';

.backLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;

  @include backButton;
}

.backLinkTitle {
  font-family: $poppinsFontStyle;
  font-weight: $mediumFontWeight;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  margin-left: 8px;
}
