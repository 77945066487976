@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  @include stepsContainer;
  overflow: hidden;
  --label-width: 80px;
  --logo-width: 52px;
}

.loaderContainer {
  margin: 8px auto;
}

.content {
  height: auto;
  overflow: hidden;
  transition: all 600ms linear;
  flex: 1;
}

.text {
  color: $black100;
}

.stepsContainer {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.steps {
  width: 100%;
  max-width: 420px;
  display: flex;

  .item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $white;
    border: 1px solid $black10;

    min-height: 118px;

    @include sm {
      min-height: 128px;
    }

    box-shadow: $deepBoxShadow;
    border-radius: 12px;

    .logoContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 48px;
    }

    .logoContainer > svg {
      width: var(--logo-width);
    }

    .label {
      text-align: center;
      max-width: var(--label-width);
      @include body-text('sm', 400);
      @include sm {
        @include body-text('md', 400);
      }
    }
  }

  .arrowIcon {
    width: 32px;
    transform: rotate(270deg);
    margin: auto -8px;
  }

  .planneryLogo {
    width: 70px !important;
  }
}

.stepsFullSwitch {
  margin: 0 auto 24px;
  max-width: 420px;
  text-align: center;

  .step {
    display: flex;
    margin-top: -8px;

    background: $white;
    border: 1px solid $black10;
    /* Drop Shadow / Active Card */

    box-shadow: $deepBoxShadow;
    border-radius: 12px;
    padding: 16px;

    h2 {
      @include h2;
      min-width: 23px;
    }

    .stepWrapper {
      width: 100%;
    }

    .lineWrapper {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 16px;

      &.marginTop {
        margin-top: 12px;
      }

      .label {
        text-align: left;
        @include body-text('lg', 400);
        width: 60%;
        margin-left: 16px;
        color: $black60;
        line-height: 112%;

        b {
          color: $black100;
        }
      }

      .plusLabel {
        width: 60%;

        .inner {
          background-color: $irisLight;
          border-radius: 12px;
          padding: 0 13px;
          @include body-text('lg', 700);
          color: $black100;
          margin-right: auto;
          width: fit-content;
        }
      }
    }

    .planneryLogo {
      width: 80px !important;
      height: 20px !important;
    }
  }

  .downArrowIcon {
    margin-top: -8px;
    margin-bottom: -8px;
  }
}

.logoContainer {
  min-width: var(--label-width);
  width: var(--label-width);
  text-align: center;
}

.employerLogo {
  width: var(--logo-width);
}

.anchor {
  color: $primaryColor;
  font-weight: $mediumFontWeight;
  text-decoration: none;
}

.repaymentStep {
  max-height: 0;
  opacity: 0;
  transition: all 500ms linear;
  @include verticalGap(32px);
}

.enabled {
  opacity: 1;
  max-height: 1000px;
}

.contactUs {
  color: $primaryColorLight;
  font-weight: $mediumFontWeight;
}
