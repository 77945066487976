@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  position: relative;
  padding-left: 52px;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;
  min-height: 36px;
  display: flex;
  align-items: center;
}

.checkboxLabel {
  font-size: 14px;
  line-height: 22px;
  color: $black80;

  @include sm {
    font-size: 16px;
    line-height: 28px;
  }
}

.container input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 1px solid $black10;
  box-sizing: border-box;
  filter: drop-shadow($boxShadow);
  background-color: $white;
}

.checkboxCheckmark {
  border-radius: 12px;
}

.container:hover input ~ .checkmark {
  filter: drop-shadow(0 8px 32px rgba(48, 76, 108, 0.08));
  transition: 0.3s background;
  border: 1px solid $black20;
}

.container input:checked ~ .checkmark {
  border: 0;
  background: linear-gradient(268.9deg, #47e497 3.88%, #47e0d6 99.06%);
}

.checkmark::after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark::after {
  display: block;
}

.container .checkboxCheckmark::after {
  content: url('checkmark.svg');
  width: 16px;
  height: auto;
}
