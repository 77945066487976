@import 'src/styles/variables';
@import 'src/styles/mixins';

.title {
  @include h1;
  text-align: center;
  margin-bottom: 16px;
}

.label {
  color: $black100;
  text-align: center;
}

.colleagues {
  @include verticalGap(8px);
  margin: 20px 0;
}

.colleagueRow {
  display: flex;
  align-items: center;
}

.userIcon {
  rect {
    fill: $purple50;
  }

  path {
    fill: $purple400;
  }
}

.colleagueName {
  margin-left: 10px;
  font-size: 14px;
  color: $black100;
}

.colleagueSavings {
  font-weight: $mediumFontWeight;
  font-size: 14px;
  color: $black100;
  margin-left: auto;
}

.testimonial {
  @include body-text('md');

  padding: 24px;
  display: flex;
  gap: 12px;
  color: $black60;
  box-shadow: $deepBoxShadow;
  margin-bottom: 32px;
  border-radius: 12px;
  border: 1px solid $black10;
  align-items: center;

  .image {
    margin-bottom: 12px;
    max-width: 124px;
    object-fit: contain;
  }
}

.button {
  margin-bottom: 20px;
}
