@import 'src/styles/variables';
@import 'src/styles/media';

@mixin input {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  font-weight: $regularFontWeight;
  text-overflow: ellipsis;
  cursor: pointer;
  appearance: none;
  padding: 15px;
  background: $black6;
  font-family: $poppinsFontStyle;
  border: 1px solid transparent;
  border-radius: 10px;
  font-size: 16px;
  line-height: 28px;
  height: 48px;
  transition: border 100ms linear;

  &:focus {
    border: 1px solid $primaryColor;
  }

  &::placeholder {
    color: $black40;
    font-size: 14px;
    line-height: 28px;
  }

  &:disabled {
    background-color: $black6;
    cursor: default;
  }
}

// Typography
@mixin h1 {
  @include h2;

  @include sm {
    font-size: 30px;
    line-height: 44px;
  }
}

@mixin h2 {
  font-size: 24px;
  line-height: 38px;
  font-weight: $boldFontWeight;
}

@mixin body-text($size, $weight: 400) {
  $font-sizes: (
    'xs': 10,
    'sm': 12,
    'md': 14,
    'lg': 16,
    'xl': 24,
  );

  $value: map-get(
    $map: $font-sizes,
    $key: $size,
  );

  font-size: $value + px;
  line-height: $value * 1.72 + px;
  font-weight: $weight;
}

@mixin subtitle {
  font-size: 14px;
  line-height: 26px;
  color: $black80;

  @include sm {
    font-size: 16px;
    line-height: 28px;
  }
}

@mixin paragraph {
  font-weight: $regularFontWeight;
  font-size: 14px;
  line-height: 22px;
  color: $black100;
}

@mixin cardContainer {
  width: auto;
  border-radius: 24px;
  background-color: $white;
  padding: 16px;

  @include sm {
    width: 720px;
    margin: 0 auto;
    max-width: 864px;
    padding: 48px;
    box-sizing: border-box;
  }
}

@mixin stepsContainer {
  width: auto;
  background-color: $white;
  margin: 0 -16px 16px;
  box-shadow: $boxShadow;
  padding: 32px 16px;
  border-radius: 24px;
  box-sizing: border-box;

  @include sm {
    width: 720px;
    margin: 0 auto;
    max-width: 864px;
    padding: 48px;
    box-sizing: border-box;
  }
}

@mixin contentContainer {
  @include sm {
    background: $white;
    width: 720px;
    max-width: 864px;
    padding-right: 48px;
    padding-left: 48px;
    box-sizing: border-box;
    box-shadow: $boxShadow;
    border-radius: 24px;
  }

  @include md {
    padding-right: 112px;
    padding-left: 112px;
  }
}

@mixin offerContainer($background: false) {
  @if $background {
    background-color: $white;
    box-shadow: $boxShadow;
  }

  border-radius: 24px;
  padding: 36px 16px;

  @include sm {
    width: 720px;
    max-width: 864px;
    box-sizing: border-box;
  }
}

@mixin inputLabel {
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 12px;
  color: $black100;
}

@mixin notification {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: $black80;
}

@mixin formInputMargin {
  margin-bottom: 24px;
}

@mixin dropdownOptionsList {
  position: absolute;
  top: 64px;
  left: 0;
  z-index: $inputDropdownIndex;
  overflow-y: scroll;
  box-sizing: border-box;
  width: 100%;
  max-height: 240px;
  background-color: $white;
  filter: drop-shadow($boxShadow);
  list-style: none;
  border-radius: 10px;
}

@mixin dropdownOption {
  padding: 16px 46px 16px 16px;
  color: $black100;
  cursor: pointer;

  font-size: 16px;
  line-height: 28px;

  &:hover {
    background: $black3;
  }
}

@mixin loanFormBackLink {
  max-width: 864px;
  margin: 18px auto 0;

  @include sm {
    margin: 20px auto 0;
  }
}

@mixin backButton {
  margin-top: 8px;
  color: $black60;

  &:hover {
    color: $black100;

    svg path {
      fill: $black100;
    }
  }

  @include sm {
    width: 720px;
    padding: 0;
    margin: 20px auto 16px;
  }
}

@mixin listTitle {
  font-weight: $boldFontWeight;
  font-size: 16px;

  @include sm {
    font-size: 20px;
  }
}

@mixin listLabel {
  font-weight: $regularFontWeight;
  font-size: 14px;
}

@mixin bottomInfo {
  font-size: 12px;
  color: $black40;
}

@mixin secondaryButton {
  color: $blue;
  font-size: 12px;
  font-weight: $mediumFontWeight;
}

@mixin popupHeader {
  font-family: $poppinsFontStyle;
  font-weight: $boldFontWeight;
  font-size: 24px;
  color: $black100;

  @include sm {
    font-size: 32px;
  }
}

@mixin button {
  position: relative;
  font-family: $poppinsFontStyle;
  font-size: 16px;
  min-height: 48px;
  text-align: center;
  border: 0;
  outline: none;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.3s ease-in-out background-color, color;
}

@mixin verticalGap($amount) {
  display: flex;
  flex-direction: column;
  gap: $amount;
}
