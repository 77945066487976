@import 'src/styles/variables';
@import 'src/styles/mixins';

.descriptionItems {
  display: flex;
  flex-direction: column;
  background-color: $white;
  text-transform: none;
  color: $black100;
  font-style: normal;
  font-size: 14px;
  @include verticalGap(12px);
}

.item {
  display: grid;
  align-items: center;
  grid-template-columns: 40px 1fr;
  gap: 12px;
}

.itemText {
  @include body-text(md);
}

.formContainerMarginTop {
  // add margin on desktop view
  @media screen and (min-width: map-get($MEDIA, sm)) {
    margin-top: 32px;
  }
}

.buttonContainer {
  @include verticalGap(20px);
}

.paycheckDiagram {
  @include verticalGap(0);
}

.card {
  padding: 16px 24px;
  border-radius: 12px;
  border: 1px solid $black10;
  box-shadow: $deepBoxShadow;

  .cardIcon {
    width: 94px;
    flex-shrink: 0;
  }

  .row {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.arrow {
  margin: -8px auto;
}

.plus {
  margin: 12px auto;
  display: flex;
  width: 58px;
  justify-content: center;
  border-radius: 12px;
  background: $irisLight;
  font-size: 14px;
  font-weight: bold;
}
