@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}

.loaderContainer {
  svg {
    fill: $primaryColor;
  }
}

.anchor {
  color: $primaryColor;
  cursor: pointer;
  font-weight: $semiBoldFontWeight;
  line-height: 40px;
}

.anchor.disabled {
  cursor: default;
  color: $black60;
}
