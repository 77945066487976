@import 'src/styles/variables';
@import 'src/styles/mixins';

.inputs {
  @include verticalGap(24px);
}

.sessionExpired {
  color: $red;
  text-align: center;
  margin: 0 auto;
  font-weight: $semiBoldFontWeight;
}
