@import 'src/styles/mixins';

.container {
  @include stepsContainer;
  @include verticalGap(32px);
  z-index: 0;
  color: $black100;
}

.icon {
  text-align: center;
  margin-bottom: 16px;
}

.titleArea {
  @include verticalGap(12px);
}

.title {
  @include h1;
  text-align: center;
}

.subtitle {
  @include subtitle;
  text-align: center;
  @include verticalGap(24px);
}

.video {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  width: 100%;
  margin: 0 auto;

  video {
    max-height: 300px;
    border-radius: 12px;
  }
}
