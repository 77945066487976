@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  @include sm {
    margin: 0 auto;
    padding: 40px 80px 80px;
  }
  background-color: $white;
  border-radius: 24px;
  margin: 0 auto 30px;
  padding: 24px 24px 48px;
  max-width: 864px;
  box-sizing: border-box;
  width: 100%;
}

.signIcon {
  @include sm {
    margin: 0 auto;
  }
  width: 203px;
  margin: 0 auto 8px;

  svg {
    width: 100%;
    height: auto;
  }
}

.title {
  @include h1;
  text-align: center;
  margin-bottom: 16px;
}

.descriptionContainer {
  margin-bottom: 40px;
}

.description {
  @include subtitle;
  text-align: center;
}

.description:not(:last-child) {
  margin-bottom: 16px;
}

.button {
  display: block;
  margin: 0 auto;
}
