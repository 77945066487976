@import 'src/styles/mixins';

.text {
  text-align: center;
}

.title {
  text-align: center;
  margin: 24px 0;
}

.container {
  margin-bottom: 24px;
}

.contact {
  @include sm {
    width: 720px;
    max-width: 864px;
    box-sizing: border-box;
  }
}
