@import 'src/styles/mixins';
@import 'src/styles/variables';

.textAreaContainer {
  position: relative;
}

.textArea {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  font-weight: $regularFontWeight;
  text-overflow: ellipsis;
  cursor: pointer;
  appearance: none;
  padding: 15px;
  background: #f2f4f7;
  font-family: $poppinsFontStyle;
  border: 0;
  border-radius: 12px;
  font-size: 16px;
  line-height: 28px;
  resize: none;

  &:focus {
    box-shadow: 0 0 0 3px rgba(121, 90, 247, 0.3);
  }

  &::placeholder {
    color: $black60;
    font-size: 16px;
    line-height: 28px;
  }

  &:disabled {
    background-color: $black6;
    cursor: default;
  }
}

.label {
  @include inputLabel;
}

.error {
  border: 1px solid $red;
}
