@import 'src/styles/variables';
@import 'src/styles/mixins';

.label {
  color: $black100;
  text-align: left;
}

.benefits {
  color: $black100;
  margin: 0 24px;
}

.content {
  @include verticalGap(24px);
}

.buttonContainer {
  @include verticalGap(20px);
}

.description {
  @include body-text('md', $regularFontWeight);
  text-align: center;

  sup {
    vertical-align: top;
  }
}

.descriptionList {
  @include verticalGap(8px);
  padding-inline-start: 16px;
  text-align: left;

  li {
    gap: 8px;
  }
}

.cautionChip {
  padding: 0 4px;
  border-radius: 2px;
  border: 1px solid $red500;
  color: $red600;
  @include body-text('sm', $boldFontWeight);
  text-transform: uppercase;
}
