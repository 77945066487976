@import 'src/styles/variables';
@import 'src/styles/mixins';

.titleArea {
  @include verticalGap(24px);
}

.title {
  @include h1;
  text-align: center;
}

.yearsToSaveLabel {
  color: $purple400;
  font-size: 36px;
  font-weight: 800;
  text-align: center;
  line-height: 100%;
}

.label {
  @include body-text('lg', 400);
  color: $black100;
  text-align: center;
}

.buttonContainer {
  @include verticalGap(20px);
}

.payoffContent {
  @include verticalGap(16px);
}

.tableAndAssumptions {
  @include verticalGap(24px);
}
