@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  width: 100%;
  outline: none;
}

.dropbox {
  outline: none;
  background: #e8e8fd;
  border: 1px dashed $primaryColor;
  box-sizing: border-box;
  border-radius: 16px;
  width: 100%;
  min-height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include sm {
    min-height: 200px;
  }
}

.widget {
  outline: none;
}

.uploadInput {
  outline: none;
}

.dropboxReject {
  animation: headShake 0.82s both;
  background: rgba(255, 90, 100, 0.1);
  border: 1px dashed $red;
}

.linkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  font-size: 16px;
  line-height: 28px;
  color: $primaryColor;
  margin-top: 11px;
}

.linkMobil {
  cursor: pointer;
  @include lg {
    display: none;
  }
}

.linkDesktop {
  display: none;

  @include lg {
    display: block;
  }
}

.linkDesktopHighlighted {
  text-decoration: underline;
  cursor: pointer;
}

.rejectMessage {
  font-size: 16px;
  line-height: 28px;
  color: $red;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}
