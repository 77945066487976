@import 'src/styles/variables';
@import 'src/styles/mixins';

.title {
  @include h1;
  text-align: center;
  margin-bottom: 16px;
}

.label {
  color: $black100;
  text-align: center;
}

.button {
  margin-top: 20px;
}
