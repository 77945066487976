@import 'src/styles/variables';

.cardsContainer {
  position: relative;
}

.cardsContainer::after {
  content: 'or';
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $mediumFontWeight;
  width: 36px;
  height: 36px;
  position: absolute;
  top: calc(50% + 20px); // 20px is the margin-bottom from the first card
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: $black6;
  border: 1px solid $black10;
}
