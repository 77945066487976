@import 'src/styles/variables';
@import 'src/styles/mixins';

.header {
  background: linear-gradient(45deg, $primaryColor, $blue300);
  border-radius: 0 0 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  margin: 0 -16px 16px;

  @include sm {
    width: 720px;
    margin: 0 auto 16px;
    max-width: 864px;
    padding: 24px;
    box-sizing: border-box;
  }
}

.header.aligned {
  padding: 16px 0;
}

.logo {
  margin-bottom: 16px;

  path {
    fill: $white;
  }
}

.greeting {
  font-size: 24px;
  font-weight: $boldFontWeight;
  color: $white;
  line-height: 160%;
}

.headerLabel {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  color: $white;
  padding: 12px;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.12);
  line-height: 172%;
  margin-top: 16px;
}

.debtValue {
  font-size: 20px;
  font-weight: $semiBoldFontWeight;
}
