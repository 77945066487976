@import '../../../styles/mixins';
@import '../../../styles/variables';

.container {
  @include stepsContainer;
  text-align: center;
}

.iconContainer {
  margin-top: 24px;
  margin-bottom: 24px;
}

.title {
  @include h2;
  margin-bottom: 24px;
}

.description {
  @include subtitle;
  margin-bottom: 48px;
  text-align: center;
}

.bold {
  font-weight: $boldFontWeight;
}

.shareButton {
  margin-bottom: 24px;
}

.buttonContainer {
  @include verticalGap(24px);
}
