@import 'src/styles/variables';
@import 'src/styles/mixins';

.shareLinkContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input {
  flex-grow: 1;
}

.copyLinkButton {
  @include body-text(md, $semiBoldFontWeight);
  cursor: pointer;
  color: $primaryColor;
  white-space: nowrap;
  margin-left: 8px;
}

.stepsToComplete {
  width: fit-content;
  @include body-text(md);
  color: $black80;
  text-align: center;
}
// Needs this style to make share button the same as Button.tsx
.button {
  @include button;
  background-color: $primaryColor;
  color: $white;
  font-weight: $boldFontWeight;

  > button {
    height: 48px;
    padding: 12px 24px !important;
    width: 100%;
    text-transform: uppercase;
  }
}

.buttonContainer {
  @include verticalGap(20px);
}
