@import 'src/styles/media';
@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border-radius: 24px;
  box-shadow: $deepBoxShadow;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
  max-width: 640px;
  margin: 24px auto 38px;
  z-index: $contentIndex;

  @include sm {
    max-width: 720px;
    margin: 24px auto 48px;
    padding: 40px 120px 56px;
  }
}

.title {
  @include h1;
  margin-top: 24px;
  margin-bottom: 40px;
  text-align: center;

  @include sm {
    width: 480px;
    margin: 0 0 32px -40px;
  }
}

.text {
  font-weight: $regularFontWeight;
  font-size: 14px;
  color: $black80;
  margin-top: 40px;
}

.email {
  font-weight: $mediumFontWeight;
  font-size: 14px;
  color: $black80;
}

.icon {
  width: 203px;
  height: 189px;
}
