@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  background-color: $white;
  box-shadow: $boxShadow;
  border-radius: 24px;
  max-width: 638px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px 48px;
  margin: 14px auto 48px;
  box-sizing: border-box;

  @include sm {
    padding: 24px 24px 80px;
    margin: 24px auto 48px;
    min-width: 720px;
  }
}

.description {
  @include subtitle;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}

.email {
  font-weight: $boldFontWeight;
}
