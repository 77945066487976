@import 'src/styles/variables';
@import 'src/styles/mixins';

.accountInfoTitle {
  @include body-text(lg, $boldFontWeight);
  margin-bottom: 12px;
}

.accountItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.accountItemName {
  color: $black60;
}

.accountItemNumber {
  display: flex;
  align-items: center;
  @include body-text(md, $boldFontWeight);
}

.copyIcon {
  cursor: pointer;
  margin-left: 8px;
  width: 16px;
  height: 16px;
}

.button {
  margin-top: 16px;
}
