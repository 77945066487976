@import 'src/styles/variables';
@import 'src/styles/mixins';

.progressData {
  display: flex;
  justify-content: space-between;
  @include body-text(md);
  margin-bottom: 4px;
}

.progressBarContainer {
  position: relative;
  border-radius: 7px;
  width: 100%;
  height: 8px;
  background-color: $black6;
  margin-bottom: 8px;
}

.progressBarCompleted {
  position: absolute;
  top: 0;
  left: 0;
  height: 8px;
  background: #b39cfc;
  border-radius: 7px;
  width: var(--progress);
  transition: width 400ms ease-in-out;
}

.progressBarCompleted.withLabel::after {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $primaryColor;
  position: absolute;
  top: -4px;
  left: calc(100% - 12px);
  border: 4px solid rgba(156, 130, 250, 0.5);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.progressLabel,
.originalPositionLabel {
  @include body-text(xs);
  position: absolute;
  top: 12px;
  left: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
}

.labelName {
  white-space: nowrap;
}

.originalPosition {
  position: absolute;
  top: 0;
  left: calc(var(--position) - 4px);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: $primaryColor;
}

.hidden {
  display: none;
}

.overflowingLeft {
  left: calc(100% - 8px);

  @include sm {
    left: calc(100% - 24px);
  }
}

.overflowingRight {
  right: 0;
  left: auto;
}

.titleOverlapped {
  margin-left: auto;
}
