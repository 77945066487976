@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  @include verticalGap(16px);
}

.option {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.radio {
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid $black10;
  border-radius: 50%;
}

.radio.selected {
  width: 8px;
  height: 8px;
  border: 6px solid $primaryColor;
}

.label {
  color: $black80;
}
