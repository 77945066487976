@import 'src/styles/mixins';
@import 'src/styles/variables';

.stateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;

  .icon {
    width: 128px;
    height: 128px;
    margin-bottom: 32px;
  }

  .iconLarge {
    width: 220px;
    height: 220px;
    margin-bottom: 32px;
  }

  .title {
    @include h1;

    @include sm {
      width: 420px;
    }

    text-align: center;
    margin: 0 auto;
    width: 300px;
  }
}
