@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: $pageLoaderIndex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $primaryColor;
  width: 100%;
  height: 100%;
}

.title {
  @include h1;
  color: $white;
  margin-bottom: 8px;
  padding: 0 40px;
  text-align: center;
}

.subtitle {
  @include subtitle;
  color: $white;
  margin-bottom: 20px;
  text-align: center;
}
