.graphCard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 12px;
}

.icon {
  margin-right: 12px;
}
