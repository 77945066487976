@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  width: 100%;
}

.header {
  cursor: pointer;
  padding: 36px 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    margin-left: 8px;
    font-weight: 600;
  }

  .icon {
    transition: transform 200ms linear;

    &.open {
      transform: rotate(90deg);
    }
  }
}

.content {
  overflow: hidden;
  transition: height 200ms linear;

  .subtitle {
    margin: 0 0 12px 8px;
  }
}

.content.toggleDropdown {
  overflow: visible;
}

.notRecommended {
  padding-top: 24px;
  border-top: 1px solid $black10;

  .list {
    .row {
      @include body-text(sm, 500);
      color: $black80;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &:last-child {
        padding-bottom: 8px;
      }
    }
  }
}
