@import 'src/styles/variables';
@import 'src/styles/mixins';

.label {
  color: $black100;
  text-align: left;
}

.benefits {
  color: $black100;
  margin: 0 24px;
}

.content {
  @include verticalGap(24px);
}

.buttonContainer {
  @include verticalGap(20px);
}
