@import 'src/styles/mixins';

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

.button {
  width: 100%;

  @include sm {
    max-width: 720px;
    margin: auto;
  }
}

.reviewLink {
  justify-content: flex-start;
}

.reviewIcon {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
}
