@import 'src/styles/media';
@import 'src/styles/mixins';

.locationError {
  @include contentContainer;
  display: flex;
  flex-direction: column;
  justify-content: normal;
  flex: 1;
  justify-content: space-between;

  @include sm {
    background: none;
    box-shadow: none;
    margin: 24px auto 48px;
    flex: 0;
  }
}

.locationIconContainer {
  width: 155px;
  margin: 0 auto 24px;

  @include sm {
    margin: 0 auto 40px;
  }
}

.title {
  @include h1;
  text-align: center;
  margin-top: 14px;
  margin-bottom: 24px;

  @include sm {
    margin-top: 0;
    margin-bottom: 16px;
  }
}

.button {
  display: block;
  margin: 0 auto 24px;
  flex: 0;

  @include sm {
    margin: 0 auto;
    max-width: 400px;
  }
}

.subtitle {
  @include subtitle;
  text-align: center;
  margin-bottom: 24px;
}

.emailInput {
  margin-bottom: 24px;
}

.phoneInput {
  margin-bottom: 40px;
}

.backLink {
  @include backButton;
}
