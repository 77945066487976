@import 'src/styles/variables';
@import 'src/styles/mixins';

.inputContainer {
  position: relative;
}

.input {
  @include input;
}

.label {
  @include inputLabel;
}

.error {
  border: 1px solid $red;
}
