@import 'src/styles/variables';
@import 'src/styles/mixins';

.label {
  color: $black80;
}

.hint {
  text-align: center;
  color: $black80;
  font-size: 14px;
}

.checkboxList {
  @include verticalGap(16px);
}
