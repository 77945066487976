@import 'src/styles/mixins';

.formInput:not(:last-child) {
  @include formInputMargin;
}

.inputs {
  margin-bottom: 48px;
  width: 100%;
}
