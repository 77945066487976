@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  margin-top: 24px;
  margin-bottom: 80px;

  @include sm {
    margin-top: 80px;
  }
}

.title {
  @include h1;
  margin-bottom: 8px;
  text-align: center;

  @include sm {
    margin-bottom: 16px;
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  max-width: 500px;
  margin: 24px 0;
}
