@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  @include stepsContainer;
  text-align: center;
}

.iconContainer {
  margin: 42px;
}

.title {
  @include h2;
  margin-bottom: 56px;
  overflow: hidden;
  text-overflow: ellipsis;
}
