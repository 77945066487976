@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.step {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 0;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.step:not(:last-child)::after {
  content: '';
  height: calc(100% + 5px);
  width: 1px;
  background-color: $black20;
  top: 32px;
  left: 3px;
  position: absolute;
}

.stepDot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  border: 1.5px solid $black60;
  margin-right: 12px;
  z-index: 1;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.name {
  @include body-text('lg', $boldFontWeight);
}
