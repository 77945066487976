.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  text-align: center;
  margin-bottom: 24px;
}
