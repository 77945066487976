@import 'src/styles/variables';
@import 'src/styles/mixins';

.inputContainer {
  position: relative;

  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
  margin: auto;
}

.input {
  @include input;

  width: 100%;
  height: 60px;
  padding: 0;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
}

.label {
  font-size: 14px;
  line-height: 22px;
  margin: 0 0 6px 8px;
  color: $black80;
}

.error {
  border: 1px solid $red;
}
