@import 'src/styles/variables';
@import 'src/styles/mixins';

.loaderContainer {
  width: 100%;
  height: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  @include stepsContainer;
  color: $black100;
}

.title {
  font-size: 20px;
  font-weight: $boldFontWeight;
  text-align: center;
  margin-bottom: 32px;
}

.button {
  margin-top: 24px;
}
