@import 'src/styles/mixins';

.subtitleText {
  margin-bottom: 16px;
}

.inputs {
  width: 100%;
}

.formInput:not(:last-child) {
  @include formInputMargin;
}
