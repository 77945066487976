@import 'src/styles/variables';
@import 'src/styles/mixins';

.dropdownContainer {
  position: relative;
}

.dropdownOptionsList {
  @include dropdownOptionsList;
  top: 92px;
}

.dropdownOption {
  @include dropdownOption;
}

.inputs {
  @include verticalGap(24px);
}
