.reasonSelect {
  margin-top: 12px;
}

.reasonInput {
  margin-top: 24px;
}

.nextButton {
  margin-top: 32px;
}

.text {
  text-align: center;
  margin-bottom: 12px;
}
