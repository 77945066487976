@import '../../styles/variables';
@import '../../styles/mixins';

.buttonContainer {
  @include verticalGap(20px);
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  background-color: $white;

  .fullscreenButton {
    position: absolute;
    top: 0;
    right: 8px;
    z-index: 1;
  }
}

.signatureContainer {
  @include verticalGap(20px);
  align-items: center;

  canvas {
    border: 1px solid $black100;
    border-radius: 8px;
    width: 400px;
    height: 140px;
  }
}

.uploaded {
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0 auto;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
}
