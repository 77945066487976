@import 'src/styles/variables';
@import 'src/styles/mixins';

.label {
  color: $black100;
  text-align: center;
}

.buttonContainer {
  @include verticalGap(20px);
}
