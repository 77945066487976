@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  display: flex;
  justify-content: center;
  position: relative;
}

.comparisonLabel {
  position: absolute;
  top: 20px;
  z-index: 1;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: $white;
  font-weight: $mediumFontWeight;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $black10;
}

.tableContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 12px;
  position: relative;
}

.tableColumn {
  width: 50%;
  text-align: center;
  border: 1px solid $black10;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: $deepBoxShadow;
}

.tableColumn.highlighted {
  border: 1px solid $purple100;
}

.highlighted {
  color: $purple400;
}

.title {
  padding: 10px;
  background: $black6;
  color: $black100;
}

.tableColumn.highlighted .title {
  background: $purple50;
}

.columnBody {
  padding: 10px;
  display: grid;
  grid-template-rows: auto;
  gap: 10px;
}

.amount {
  font-weight: $boldFontWeight;
}

.label {
  color: $black60;
  font-size: 14px;
}
