@import 'src/styles/variables';
@import 'src/styles/mixins';

.innerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sectionTitle {
  font-size: 14px;
  font-weight: $semiBoldFontWeight;
}

.header.centered {
  justify-content: center;

  .sectionTitle {
    margin-right: 20px;
  }
}

.header .centered {
  display: flex;
  justify-content: center;
}
